import request from "@/utils/request";
import qs from 'qs'
/* 手机号码修改 */
// /back/hand/call/updateStudentAccount
/* 兼职销售 */
/* 兼职销售分页查询 */
export const apiGetParttimeSaleList = (params) => {
    return request.get('/back/partTime/sales/pageList', params)
}
/* 兼职销售删除 */
export const apiDeleteParttimeSale = (params) => {
    return request.post(`/back/partTime/sales/remove?${qs.stringify(params, { indices: false })}`)
}
/* 兼职销售新增 */
export const apiAddParttimeSale = (params) => {
    return request.post(`/back/partTime/sales/save`, params)
}
/* 兼职销售修改 */
export const apiEditParttimeSale = (params) => {
    return request.post('/back/partTime/sales/update', params)
}
/* 兼职销售有效无效 */
export const apiStatusParttimeSale = (params) => {
    return request.post(`/back/partTime/sales/update/state?${qs.stringify(params, { indices: false })}`)
}
/* 跟进记录 */
/* 跟进记录列表 */
export const apiGetFollowList = (params) => {
    return request.get('/back/student/followupRecord/list', params)
}
/* 删除跟进记录 */
export const apiDeleteFollow = (params) => {
    return request.post(`/back/student/followupRecord/remove?${qs.stringify(params, { indices: false })}`)
}
/* 新增跟进记录 */
export const apiAddFollow = (params) => {
    return request.post('/back/student/followupRecord/save', params)
}
/* 修改跟进记录 */
export const apiEditFollow = (params) => {
    return request.post('/back/student/followupRecord/update', params)
}


/* 学生删除 */
export const apiDeleteStudent = (params) => {
    return request.post(`/back/student/sale/dial/batchRemove?${qs.stringify(params, { indices: false })}`)
}
/* 学生详情 */
export const apiGetStudentDetail = (params) => {
    return request.get('/back/student/pool/getDetailById', params)
}
/* 获取销售和兼职列表 */
export const apiGetSaleAndParttimeList = (params) => {
    return request.get('/back/student/pool/getSaleAndParttimeList', params)
}
/* 呼出通话记录分页查询 */
export const apiGetPoolCallList = (params) => {
    return request.get('/back/student/pool/callLog/pageList', params)  
}

/* 学生是否有效 */
export const apiSetStudentEffective = (params) => {
    return request.post(`/back/student/sale/dial/setEffective?${qs.stringify(params, { indices: false })}`)
}

/* 获取黑名单列表 */
export const apiGetBlackList = (params) => {
    return request.get('/back/student/pool/blackList/pageList', params)
}

/* 审核黑名单 */
export const apiAuditingBlack = (params) => {
    return request.post(`/back/student/pool/auditingBlackList?${qs.stringify(params, { indices: false })}`)
} 

/* 移除黑名单 */
export const apiRemoveBlackList = (params) => {
    return request.post(`/back/student/pool/removeBlackList?${qs.stringify(params, { indices: false })}`)
}

/* 删除意向表分页查询 */
export const apiGetDelectIntentionList = (params) => {
    return request.get('/back/student/sale/dial/deleteIntention/pageList', params)
}

/* 查询系统中所有销售 */
export const apiGetAllSaleList = () => {
    return request.get('/back/student/sale/dial/getAllSales')
}

/* 删除意向列表批量分配学生 */
export const apiBatchAlloction = (params) => {
    return request.post(`/back/student/sale/dial/batchAllocation?${qs.stringify(params, { indices: false })}`)
}

/* 删除意向列表批量恢复学生 */
export const apiBatchRestore = (params) => {
    return request.post(`/back/student/sale/dial/batchRestore?${qs.stringify(params, { indices: false })}`)
}

/* 导入数据学生分页查询 */
export const apiGetBatchRecordList = (params) => {
    return request.get('/back/student/pool/batchRecord/pageList', params)
}
/* 删除导入的学生 */
export const apiDeleteBatchRecordList = (params) => {
    return request.post(`/back/student/pool/deleteStudentBatchRecord?${qs.stringify(params, { indices: false })}`)
}
/* 导入批次数据是否开启外呼 */
export const apiSetOpenOutBound = (params) => {
    return request.post(`/back/student/pool/setOpenOutbound?${qs.stringify(params, { indices: false })}`)
}
/* 导入批次学生 */
export const apiImportFileStudent = (params, file) => {
    let formData = new FormData()
    formData.append('files', file)
    return request.post(`/back/student/pool/importFile?${qs.stringify(params, { indices: false })}`, formData, {
        headers: {
            'Content-type': 'multipart/form-data'
        },
        timeout: 0,   // 导入文件超时时间
    })
}
/* 导入批次学生test */
export const apiImportFileStudentTest = (params, file) => {
    let formData = new FormData()
    formData.append('files', file)
    return request.post(`/back/student/pool/testImportFile?${qs.stringify(params, { indices: false })}`, formData, {
        headers: {
            'Content-type': 'multipart/form-data'
        },
        timeout: 0,   // 导入文件超时时间
    })
}
/* 将导入批次数据分配给销售 */
export const apiImportAllocToSales = (params) => {
    return request.post('/back/student/pool/allocationToSales', params)
}
/* 查询系统中所有销售 */
export const apiGetAllSalesForImportAlloc = (params = {}) => {
    return request.get('/back/student/pool/getAllSales', params)
}
/* 根据批次id查询分配详情 */
export const apiGetImportAllocDetail = (params) => {
    return request.get('/back/student/pool/getDetailByBatchRecordId', params)
}
/* 单批次数据详情分页查询 */
export const apiGetBatchDetail = (params) => {
    return request.get('/back/student/pool/batchDetail/pageList', params)
}
/* 单批次统计分页查询 */
export const apiGetBatchStatistics = (params) => {
    return request.get('/back/student/pool/batchStatistics/pageList', params)
}
/* 电话管理池总电话池分页查询 */
export const apiGetPoolStudentList = (params) => {
    return request.get('/back/student/pool/phone/pageList', params)
}

/* 将某一批次个别学生加入黑名单 */
export const apiSetToBlackList = (params) => {
    return request.post(`/back/student/pool/setToBlackList?${qs.stringify(params, { indices: false })}`)
}

/* 获取批次列表 */
export const apiGetImportListNotPage = () => {
    return request.get('/back/student/pool/getBatchRecordList')
}

/* 查看外呼任务详情 */
export const apiGetCallTaskDetail = (params) => {
    return request.get("/dky/predictCall/info", params)
}
/* 关闭外呼任务 */
export const apiCallListClose = (params) => {
    return request.post(`/dky/predictCall/close?${qs.stringify(params, { indices: false })}`)
}
/* 创建预测外呼任务 */
export const apiCreateCallList = (params) => {
    return request.post(`/dky/predictCall/create`, params)
}
/* 修改预测外呼任务 */
export const apiUpdateCallTask = (params) => {
    return request.post('/dky/predictCall/update', params)
}
/* 预测外呼分页记录 */
export const apiGetCallListPage = (params) => {
    return request.post(`/dky/predictCall/list?${qs.stringify(params, { indices: false })}`)
}
/* 预测外呼被叫号码分页记录 */
export const apiGetListByBatchId = (params) => {
    return request.post(`/dky/predictCall/number/list?${qs.stringify(params, { indices: false })}`)
}
/* 启动外呼任务 */
export const apiStartCallList = (params) => {
    return request.post(`/dky/predictCall/start?${qs.stringify(params, { indices: false })}`)
}
/* 暂停外呼任务 */
export const apiPauseCallList = (params) => {
    return request.post(`/dky/predictCall/stop?${qs.stringify(params, { indices: false })}`)
}
/* 删除外呼任务 */
export const apiDelectCallList = (params) => {
    return request.post(`/dky/predictCall/delete?${qs.stringify(params, { indices: false })}`)
}


/* 订单 */
/* 新增订单 */
export const apiAddOrder = (params) => {
    return request.post('/back/order/add', params)
}
/* 订单删除 */
export const apiDeleteOrder = (params) => {
    return request.post('/back/order/delete', params)
}
/* 订单详情 */
export const apiOrderDetail = (params) => {
    return request.get('/back/order/detail', params)
}
/* 订单分页查询 */
export const apiGetOrderList = (params) => {
    return request.get('/back/order/pageList', params)
}
/* 学生订单集合 */
export const apiGetStudentOrderList = (params) => {
    return request.get('/back/order/orderList', params)
}
/* 修改订单 */
export const apiUpdateOrder = (params) => {
    return request.post('/back/order/update', params)
}
/* 签约学生分页查询 */
export const apiGetOrderSignList = (params) => {
    return request.get('/back/order/signing/pageList', params)
}

/* 兼职每日统计 */
export const apiGetParttimeStatistics = (params) => {
    return request.get('/back/sale/commissioner/parttimeStatisticsManager/pageList', params)
}


/* 兼职外呼列表 */
/* 获取学生详情 */
export const apiGetCallStudentDetail = (params) => {
    return request.get('/back/student/dial/record/detail', params)
}
/* 当前兼职所属销售拨打状态 */
export const apiGetSaleStatus = () => {
    return request.get('/back/student/dial/record/getSaleExtensionStatus')
}
/* 兼职外呼列表分页查询 */
export const apiGetAutoCallList = (params) => {
    return request.get('/back/student/dial/record/pageList', params)
}
/* 兼职我的有用分页查询 */
export const apiGetParttimeMyUsefulList = (params) => {
    return request.get('/back/student/dial/record/myUseful', params)
}
/* 新增学生信息 */
export const apiAutoCallAddStudent = (params) => {
    return request.post(`/back/student/dial/record/save?${qs.stringify(params, { indices: false })}`)
}
/* 转给销售 */
export const apiCallTransferredToSale = (params) => {
    return request.post(`/back/student/dial/record/transferredToSale?${qs.stringify(params, { indices: false })}`)
}
/* 修改外呼列表学生详情 */
export const apiUpdateAutoCallInfo = (params) => {
    return request.post('/back/student/dial/record/update/studentPool', params)
}
/* 修改外呼列表学生详情副本 */
export const apiUpdateAutoCallInfoCopy = (params) => {
    return request.post('/back/student/dial/record/update/studentInfo', params)
}

/* 销售专员接口 */
/* 删除/释放学生 id */
export const apiDelStuToStuPool = (params) => {
    return request.post(`/back/sale/commissioner/delStuToStuPool?${qs.stringify(params, { indices: false })}`)
}
/* 释放学生 studentId */
export const apiDelStuToStuPoolStu = (params) => {
    return request.post(`/back/sale/commissioner/delStuToStuPoolStu?${qs.stringify(params, { indices: false })}`)
}
/* 销售查看兼职意向分页查询 */
export const apiGetParttimeIntentionList = (params) => {
    return request.get('/back/sale/commissioner/parttimeIntention', params)
}
/* 发送给我 id */
export const apiParttimeSendToSale = (params) => {
    return request.post(`/back/sale/commissioner/sentToSale?${qs.stringify(params, { indices: false })}`)
}
/* 发送给我 studentId */
export const apiParttimeSendToSaleStu = (params) => {
    return request.post(`/back/sale/commissioner/sentToSaleStu?${qs.stringify(params, { indices: false })}`)
}
/* 获取兼职列表 */
export const apiGetParttimeList = () => {
    return request.get('/back/sale/commissioner/getParttimeList')
}

/* 销售我的意向分页查询 */
export const apiSaleGetMyIntentionList = (params) => {
    return request.get('/back/sale/commissioner/myIntention/pageList', params)
}
/* 销售释放学生到公海 */
export const apiSaleReleaseStudent = (params) => {  
    return request.post(`/back/sale/commissioner/releaseStudent?${qs.stringify(params, { indices: false })}`)
}
/* 销售新增默认有意向的学生 新增学生 */
export const apiSaleAddIntentionStudent = (params) => {
    return request.post('/back/sale/commissioner/save/intentionStudent', params)
}
/* 销售新增默认有意向的学生 新增学生 导入学生 批量导入学生 */
export const apiSaleBatchAddIntentionStudent = (file) => {
    let formData = new FormData()
    formData.append('files', file)
    return request.post("/back/sale/commissioner/saleImportFile", formData, {
        headers: {
            'Content-type': 'multipart/form-data'
        },
        timeout: 0,   // 导入文件超时时间
    })
}
/* 修改颜色 */
export const apiSaleUpdateColor = (params) => {
    return request.post(`/back/sale/commissioner/update/color?${qs.stringify(params, { indices: false })}`)
}
/* 销售申请添加学生到黑名单 */
export const apiSaleSetToBlackList = (params) => {
    return request.post(`/back/sale/commissioner/setToBlackList?${qs.stringify(params, { indices: false })}`)
}


/* 客户池发送短信 */
export const apiPoolSendMessage = (params) => {
    return request.post(`/back/student/pool/sendMessage?${qs.stringify(params, { indices: false })}`)
}
/* 销售短信模板 */
export const apiGetTextMessageList = () => {
    return request.get('/back/sale/TextMessage/getList')
}
/* 销售添加短信模板 */
export const apiSaveTextMessage = (params) => {
    return request.post(`/back/sale/TextMessage/save?${qs.stringify(params, { indices: false })}`)
}
/* 销售单个学生短信发送记录 */
export const apiGetStudentMessageList = (params) => {
    return request.get('/back/sale/TextMessage/sendMessageRecordList', params)
}

/* 销售公海分页查询 */
export const apiGetHigeSeasList = (params) => {
    return request.get("/back/sale/commissioner/highSeas/pageList", params)
}
/* 公海领取规则详情 */
export const apiGetSeaRulesDetail = () => {
    return request.get('/back/set/rules/getDetail')
}
/* 公海领取规则设置 */
export const apiSetSeaRules = (params) => {
    return request.post('/back/set/rules/saveOrUpdate', params)
}
/* 销售公海领取学生 */
export const apiSaleReceiveStudent = (params) => {
    return request.post(`/back/sale/commissioner/receiveStudent?${qs.stringify(params, { indices: false })}`)
}
/* 公海销售集合（专员和兼职） */
export const apiGetSaleList = (params = {}) => {
    return request.get('/back/sale/commissioner/getSalesAndParttimeList', params)
}

/* 销售查看兼职分页统计 */
export const apiGetParttimeStatisticsList = (params) => {
    return request.get("/back/sale/commissioner/parttimeStatisticsCommiss/pageList", params)
}
/* 获取销售集合 销售列表 销售专员列表（只有专员） */
export const apiGetSaleStaffList = (params) => {
    return request.get('/back/sale/commissioner/getCurrentSalesList', params)
}
/* 获取销售部门列表 */
export const apiGetSaleOrgList = () => {
    // /sf/student/account/getSaleOrgList
    return request.get('/back/sale/commissioner/getSaleOrgList')
}
/* 获取销售专员列表 */
export const apiGetSfSaleStaffList = (params) => {
    return request.get("/sf/student/account/getSaleList", params)
}
/* 获取销售部门列表 */
export const apiGetSfSaleOrgList = () => {
    return request.get('/sf/student/account/getSaleOrgList')
}
/* 销售总监重新分配 */
export const apiRedistribution = (params) => {
    return request.post(`/back/sale/commissioner/redistribution?${qs.stringify(params, { indices: false })}`)
} 
/* 销售每日统计分页 */
export const apiGetSaleStatisticsList = (params) => {
    return request.get('/back/sale/commissioner/saleTodayStatistics/pageList', params)
}
/* 意向统计分页查询 */
export const apiGetIntentionStatisticsList = (params) => {
    return request.get('/back/sale/commissioner/intentionStatistics/pageList', params)
}
/* 订单/签约统计分页 */
export const apiGetOrderStatisticsList = (params) => {
    return request.get('/back/sale/commissioner/orderStatistics/pageList', params)
}


/* 根据年级位置批次获取学生 */
export const apiGetStudentsForMessage = (params) => {
    return request.get('/back/student/pool/getStudentsByMessage', params)
}

/* 上一个下一个学生id */
export const apiGetUpAndNextIds = (params) => {
    return request.get("/back/sale/commissioner/getUpAndNextIds", params)
}



/* 获取首次手拨拨据领取详情 */
export const apiGetFirstGetRuleDetail = () => {
    return request.get("/back/set/receive/rules/getDetailHand")
}
/* 获取真实数据详情 */
export const apiGetSuccessGetRuleDetail = () => {
    return request.get("/back/set/receive/rules/getDetailGroup")
}
/* 新增/修改首次手拨领取规则 */
export const apiUpdateFirstGetRule = (params) => {
    return request.post("/back/set/receive/rules/saveOrUpdateHand", params)
}
/* 新增/修改真实数据领取详情 */
export const apiUpdateSuccessGetRule = (params) => {
    return request.post("/back/set/receive/rules/saveOrUpdateGroup", params)
}
/* 获取超过多少次进入黑名单详情 */
export const apiGetBlackRuleDetail = () => {
    return request.get("/back/set/receive/rules/getDetailBlackList")
}
/* 获取系统拨打时间详情 */
export const apiGetSysCallTimeDetail = () => {
    return request.get("/back/set/receive/rules/getDetailTime")
}
/* 新增/修改超过次数进入黑名单 */
export const apiUpdateBlackRule = (params) => {
    return request.post("/back/set/receive/rules/saveOrUpdateBlackList", params)
}
/* 新增修改系统拨打时间详情 */
export const apiUpdateSysCallTime = (params) => {
    return request.post("/back/set/receive/rules/saveOrUpdateTime", params)
}
/* 获取中转站到真实数据池天数详情 */
export const apiGetTransferToSuccessRule = (params) => {
    return request.get('/back/set/receive/rules/getDetailAllowPool', params)
}
/* 新增/修改中转站到真实数据池天数 */
export const apiUpdateTransferToSuccessRule = (params) => {
    return request.post("/back/set/receive/rules/saveOrUpdateAllowPool", params)
}
/* 真实数据查询 */
export const apiGetSuccessPoolList = (params) => {
    return request.get("/back/hand/call/allocationData/pageList", params)
}
/* 检测数据 */
export const apiChackData = (file) => {
    let formData = new FormData()
    formData.append('files', file)
    return request.post("/back/hand/call/checkData", formData, {
        headers: {
            'Content-type': 'multipart/form-data'
        },
        timeout: 0,   // 导入文件超时时间
    })
}
/* 创建手拨 */
export const apiCreateFirstHandCall = (params) => {
    return request.post(`/back/hand/call/createHandCall?${qs.stringify(params, { indices: false })}`)
}
/* 真实数据领取列表 */
export const apiGetSuccessHandList = (params) => {
    return request.get("/back/hand/call/groupReceive/pageList", params)
}
/* 未回收数据列表 */
export const apiGetNoRecoveryList = (params) => {
    return request.get("/back/hand/call/noRecovery/pageList", params)
}
/* 首日拨打池子数据列表 */
export const apiGetFirstPoolList = (params) => {
    return request.get("/back/hand/call/phone/pageList", params)
}
/* 领取数据 */
export const apiSaveGetHandData = (params) => {
    return request.post(`/back/hand/call/receive?${qs.stringify(params, { indices: false })}`)
}
/* 首次拨打领取列表 */
export const apiGetFirstHandList = (params) => {
    return request.get("/back/hand/call/receive/pageList", params)
}
/* 中转站列表 */
export const apiGetTransferStationList = (params) => {
    return request.get("/back/hand/call/transferStation/pageList", params)
}
/* 回到真实数据 */
export const apiTransferToSuccess = (params) => {
    return request.post(`/back/hand/call/goToAllow?${qs.stringify(params, { indices: false })}`)
}
/* 回到真实数据复制 */
export const apiTransferToSuccessCopy = (params) => {
    return request.post(`/back/hand/call/goToRelease?${qs.stringify(params, { indices: false })}`)
} 
/* 首次拨打上一个下一个 */
export const apiGetSaleFirstPrevAndNext = (params) => {
    return request.get("/back/hand/call/receive/getUpAndNextIds", params)
}
/* 首次拨打领取列表 */
export const apiGetSaleGetFirstList = (params) => {
    return request.get("/back/hand/call/receive/pageList", params)
}
/* 真实数据上一个下一个 */
export const apiGetSaleSuccessPrevAndNext = (params) => {
    return request.get("/back/hand/call/saleCanAllow/getUpAndNextIds", params)
}
/* 真实数据领取列表 */
export const apiGetSaleGetSuccessList = (params) => {
    return request.get("/back/hand/call/saleCanAllow/pageList", params)
}
/* 获取加入有用规则 */
export const apiGetAddUseDetail = () => {
    return request.get('/back/set/receive/rules/getDetailUserful')
}
/* 更新加入有用规则 */
export const apiUpdateAddUseRule = (params) => {
    return request.post("/back/set/receive/rules/saveOrUpdateUserful", params)
}
/* 获取首次拨打领取详情/获取真实数据领取详情 */
export const apiGetGetUserDetail = (params) => {
    return request.get("/back/hand/call/getUserCallNum", params)
}

/* 获取销售业绩列表 */
export const apiGetSalePerformanceList = (params) => {
    return request.get("/back/set/target/sale/pageList", params)
}
/* 保存销售业绩目标 */
export const apiSaveSalePerformance = (params) => {
    return request.post("/back/set/target/sale/save", params)
}
/* 更新销售业绩目标 */
export const apiUpdateSalePerformance = (params) => {
    return request.post("/back/set/target/sale/update", params)
}
/* 删除销售业绩目标 */
export const apiDeleteSalePerformance = (params) => {
    return request.post(`/back/set/target/sale/delete?${qs.stringify(params, { indices: false })}`)
}
/* 获取销售目标业绩详情 */
export const apiGetSalePerformanceDetail = (params) => {
    return request.get("/back/set/target/sale/getDetail", params)
}

/* 销售主管-本周报表 */
export const apiGetSaleWeekReport = (params) => {
    return request.get("/back/sale/commissioner/getSaleReportFormsList", params)
}

/* 是否到访 */
export const apiSaleUpdateIsVisit = (params) => {
    return request.post(`/back/sale/commissioner/update/visit?${qs.stringify(params, { indices: false })}`)
}
/* 今日概览-公司部门排行榜 */
export const apiGetCompanySectionRank = (params) => {
    return request.get("/back/sale/board/statistics/getChartsList", params)
}
/* 今日概览-漏斗图 */
export const apiSaleGetMyPerformance = (params) => {
    return request.get("/back/sale/board/statistics/getFunnelPlot", params)
}
/* 今日概览-本月业绩 */
export const apiSaleGetMonthPerformance = (params) => {
    return request.get("/back/sale/board/statistics/getMonthPerformance", params)
}
/* 今日概览-业绩趋势饼状图 */
export const apiSaleGetPerformancePieTrend = (params) => {
    return request.get("/back/sale/board/statistics/getPerformanceTrendCake", params)
}
/* 今日概览-业绩趋势折线图 */
export const apiSaleGetPerformanceLineTrend = (params) => {
    return request.get("/back/sale/board/statistics/getPerformanceTrendLine", params)
}
/* 今日概览-今日数据 */
export const apiSaleGetTodayData = (params) => {
    return request.get("/back/sale/board/statistics/getSaleData", params)
}
/* 今日概览-本月任务 */
export const apiSaleGetMonthTask = (params) => {
    return request.get("/back/sale/board/statistics/getTask", params)
}
/* 今日概览-通话数据 */
export const apiGetCallTaskData = (params) => {
    return request.get("/back/sale/board/statistics/getCallTask", params)
}
/* 坐席监控 */
/* 监控列表 */
export const apiGetSaleCallList = (params) => {
    return request.get("/back/sale/commissioner/getSeatMonitorInfo", params)
}
/* 坐席退出登录 */
export const apiSetSaleCallLogout = (params) => {
    return request.post(`/back/sale/commissioner/agentLogout?${qs.stringify(params, { indices: false })}`)
}
/* 坐席置忙置闲 */
export const apiSetSaleCallState = (params) => {
    return request.post("/back/sale/commissioner/updateAgentState", params)
}
/* 坐席启用禁用 */
export const apiSetSaleIsCanCall = (params) => {
    return request.post(`/back/sale/commissioner/setDkyAgentType?${qs.stringify(params, { indices: false })}`)
}

/* 保存手拨通话记录 */
export const apiSaveSaleCallRecord = (params) => {
    return request.post("/back/hand/call/saveUserCallRecord", params)
}

/* 员工拨打记录列表 */
export const apiGetCallHistoryList = (params) => {
    return request.get("/back/hand/call/userCallRecord/pageList", params)
}

/* 加入黑名单运营商 */
export const apiCallSysAddBlack = (params) => {
    return request.post(`/back/student/pool/addBlackListOperator?${qs.stringify(params, { indices: false })}`)
}

/* 设置提醒天数 */
export const apiSetRemindRule = (params) => {
    return request.post("/back/set/receive/rules/saveOrUpdateRemindRule", params)
}
/* 点控云 */
/* 新增坐席 */
export const apiAddCallAccount = (params) => {
    return request.post("/back/callerIdNum/save", params)
}
/* 修改坐席 */
export const apiUpdateCallAccount = (params) => {
    return request.post("/back/callerIdNum/updateById", params)
}
/* 删除坐席 */
export const apiDeleteCallAccount = (params) => {
    return request.post(`/back/callerIdNum/remove?${qs.stringify(params, { indices: false })}`)
}
/* 坐席列表 */
export const apiGetCallAccountList = (params) => {
    return request.get("/back/callerIdNum/pageList", params)
}
/* 分配 */
export const apiAllocCallAccount = (params) => {
    return request.post("/back/callerIdNum/allowUser", params)
}
/* 优音云坐席 */
/* 新增坐席 */
export const apiAddYYYCallAccount = (params) => {
    return request.post("/back/callerIdNum/yyy/save", params)
}
/* 修改坐席 */
export const apiUpdateYYYCallAccount = (params) => {
    return request.post("/back/callerIdNum/yyy/updateById", params)
}
/* 删除坐席 */
export const apiDeleteYYYCallAccount = (params) => {
    return request.post(`/back/callerIdNum/yyy/remove?${qs.stringify(params, { indices: false })}`)
}
/* 坐席列表 */
export const apiGetYYYCallAccountList = (params) => {
    return request.get("/back/callerIdNum/yyy/pageList", params)
}
/* 分配 */
export const apiAllocYYYCallAccount = (params) => {
    return request.post("/back/callerIdNum/yyy/allowUser", params)
}
/* 获取坐席详情 点控云 */
export const apiGetCallAcountDetail = (params) => {
    return request.get("/back/callerIdNum/getDetailById", params)
}
/* 获取坐席详情 优音云 */
export const apiGetYYYCallAcountDetail = (params) => {
    return request.get("/back/callerIdNum/yyy/getDetailById", params)
}
/* 重新分配学生给销售 */
export const apiAllocStudentToSale = (params) => {
    return request.post("/back/student/pool/reAllocationStudent", params)
}
/* 获取数据管理员导入的批次列表 */
export const apiGetHandBatchRecordList = (params) => {
    return request.get("/back/student/pool/getBatchRecordList", params)
}
/* 手拨列表退回 */
export const apiHandListSendBack = () => {
    return request.post("/back/hand/call/allSendBack")
}

/* 谈单反馈 */
/* 详情 */
export const apiGetNegotiationOrderDetail = (params) => {
    return request.get("/back/negotiation/order/getInfo", params)
}
/* 新增 */
export const apiAddNegotiationOrder = (params) => {
    return request.post("/back/negotiation/order/saveInfo", params)
}

/* 获取年级分布 */
export const apiGetCallSectionDetail = (params) => {
    return request.get("/back/student/pool/section/distribution", params)
}

/* 谈单分析相关 */
/* 概览 */
export const apiGetNegotiationOrderOverviewList = (params) => {
    return request.get("/back/sale/commissioner/negotiationOrderOverview", params)
}
/* 详情 */
export const apiGetNegotiationOrderDetailList = (params) => {
    return request.get("/back/sale/commissioner/negotiationOrderDetail", params)
}
/* 助力名单 */
export const apiGetNegotiationOrderSale = (params) => {
    return request.get("/back/sale/commissioner/getSaleAssistanceNameList", params)
}
/* 跟单过程 */
export const apiGetNegotiationOrderProcess = (params) => {
    return request.get("/back/sale/commissioner/getSaleNegotiationOrderProcess", params)
}

/* 获取兼职拨打记录 */
export const apiGetParttimeCallHistoryList = (params) => {
    return request.get("/back/student/pool/parttime/callLog/pageList", params)
}
/* 专员发送兼职的学生给自己 */
export const apiSaleStaffAddIntention = (params) => {
    return request.post(`/back/student/pool/addIsIntention?${qs.stringify(params, { indices: false })}`)
}


/* 签约学生分页 */
export const apiGetSignStudentList = (params) => {
    return request.get("/sf/student/pool/signStudent/pageList", params)
}


/* 获取最新一条录音 */
export const apiGetLastCallRecord = (params) => {
    return request.get("/back/student/pool/getFboccRecID", params)
}

/* 获取用户公海新增数量 */
export const apiGetUserSeasNum = (params) => {
    return request.get("/sys/user/getUserHighSeasNum", params)
}

/* 用户公海数量清零 */
export const apiUserSeasNumSetZero = (params) => {
    return request.post(`/sys/user/userHighSeasNumZero?${qs.stringify(params, { indices: false })}`)
}
/* 获取销售个人库释放时间详情 */
export const apiGetPersonalStoreDetail = () => {
    return request.get("/back/student/personal/getDetailPersonalStore")
}
/* 设置销售个人库释放时间 */
export const apiSetPersonalStore = (params) => {
    return request.post("/back/student/personal/saveOrUpdatePersonalStore", params)
}
/* 导入个人库学生 */
export const apiImportPersonalStoreFile = (file) => {
    let formData = new FormData()
    formData.append('files', file)
    return request.post("/back/student/personal/importFile", formData, {
        headers: {
            'Content-type': 'multipart/form-data'
        },
        timeout: 0,   // 导入文件超时时间
    })
}
/* 获取销售个人库列表 */
export const apiGetPersonalList = (params) => {
    return request.get("/back/student/personal/pageList", params)
}
/* 销售个人库列表上一个下一个 */
export const apiGetPersonalPrevAndNextIds = (params) => {
    return request.get("/back/student/personal/pageList/getUpAndNextIds", params)
}
/* 释放学生到手拨池子 */
export const apiRealeaseStudentToHandCall = (params) => {
    return request.post(`/back/student/personal/releaseStudentHandCall?${qs.stringify(params, { indices: false })}`)
}
/* 销售新增个人库学生 */
export const apiAddPersonalStudent = (params) => {
    return request.post("/back/student/personal/save/personalStudent", params)
}

/* 真实数据池子年级分布 */
export const apiGetCallPollSectionInfo = () => {
    return request.get("/back/hand/call/allocationData/section/distribution")
}

/* 修改批次标题 */
export const apiEditStudentBatchInfo = (params) => {
    return request.post("/back/student/pool/updateStudentBatchRecord", params)
}
/* 获取销售专员兼职教务专员列表 */
export const apiGetStaffAndParttimeAndEduList = (params) => {
    return request.get("/back/student/pool/getUserList", params)
}

/* 获取毕业生列表 */
export const apiGetBiYeShengList = (params) => {
    return request.get("/back/student/pool/biyesheng/pageList", params)
}

/* 销售打电话平台设置 */
export const apiSetCallPlatform = (params) => {
    return request.post("/sys/user/setCallPlatform", params)
}

/* 获取优音云坐席状态 */
export const apiGetYYYAgentStatus = (params) => {
    return request.get("/yyy/getMonitorCTIStatus", params)
}

/* 获取学生详情，通过学生手机号码获取 */
export const apiGetStudentDetailByAccount = (params) => {
    return request.get("/back/student/pool/getDetailByAccount", params)
}

/* 获取坐席外显号码 */
export const apiGetCallAccountShowNum = (params) => {
    return request.get("/yyy/getMemo", params)
}

/* 设置坐席外显号码 */
export const apiSetCallAccountShowNum = (params) => {
    return request.get("/yyy/setMemo", params)
}

/* 电话设置-获取销售和兼职列表 */
export const apiGetSaleAndParttimePageList = (params) => {
    return request.get("/back/student/pool/getSaleAndParttime/pageList", params)
}
/* 禁止轮转分页查询 */
export const apiGetNoWanderStuList = (params) => {
    return request.get("/back/student/pool/noWander/pageList", params)
}