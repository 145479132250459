<template>
  <div
    class="call-box-area call-box-area1"
    ref="callBoxArea"
    :style="{ ...dialogCallInfo.position, zIndex: dialogCallInfo.zIndex }"
    v-show="dialogCallInfo.show"
  >
    <div
      class="call-header"
      v-drag="{ bindParent: 1, dialogData: 'dialogCallInfoChange' }"
    >
      按住此处进行窗口拖动(专员)
      <span
        class="icon-close iconfont icon-sys-guanbi icon-close-box"
        title="关闭"
        @click="handleHiddenCallBox"
        v-show="callInfo.callStatus != 3"
      ></span>
    </div>
    <div
      class="no-call"
      v-show="
        !(
          callInfo.callStatus == 1 ||
          callInfo.callStatus == 2 ||
          callInfo.callStatus == 3 ||
          callInfo.callStatus == 5 ||
          callInfo.callStatus == 6 ||
          (callInfo.callStatus == 4 && !isCloseCallBox)
        )
      "
    >
      <div class="call-tip">兼职转接来电显示区域</div>
      <div class="call-tip-2" @click="handleTest">暂无来电</div>
    </div>
    <div
      v-show="
        callInfo.callStatus == 1 ||
        callInfo.callStatus == 2 ||
        callInfo.callStatus == 3 ||
        callInfo.callStatus == 5 ||
        callInfo.callStatus == 6 ||
        (callInfo.callStatus == 4 && !isCloseCallBox)
      "
      class="call-box"
    >
      <span
        class="icon-close iconfont icon-sys-guanbi"
        title="关闭"
        @click="handleCloseCallBox"
        v-show="callInfo.callStatus == 4 && !isCloseCallBox"
      ></span>
      <div class="call-box-inner">
        <div class="call-info">
          <div
            class="call-username"
            :title="callUserInfo.name ? callUserInfo.name : 'xxxx'"
          >
            {{ callUserInfo.name ? callUserInfo.name : "xxxx" }}
          </div>
          <div class="call-phone">
            {{ callUserInfo.account }}
          </div>
          <div
            class="call-tip"
            v-show="
              callInfo.callStatus == 3 ||
              callInfo.callStatus == 4 ||
              callInfo.callStatus == 5 ||
              callInfo.callStatus == 6
            "
          >
            <span class="call-status call-status-end" v-show="callInfo.callStatus == 4">
              <span>通话已结束</span>
              <span class="v-line">|</span>
              <span>{{ callInfo.duration }}</span>
            </span>
            <span class="call-status call-status-3" v-show="callInfo.callStatus == 3">
              <span>通话中</span>
              <span class="v-line">|</span>
              <span>{{ callInfo.duration }}</span>
            </span>
            <span class="call-status call-status-2" v-show="callInfo.callStatus == 5">
              <span>来电中</span>
            </span>
          </div>
          <div class="call-btn-area">
            <div
              class="call-btn call-before"
              @click="handleDKYAnswer"
              v-show="callInfo.callStatus == 5"
            >
              接听
            </div>
            <div
              class="call-btn call-ing"
              @click="handleDKYHangup"
              v-show="callInfo.callStatus == 5"
            >
              挂断
            </div>
            <div
              class="call-btn call-ing"
              @click="handleDKYHangup"
              v-show="callInfo.callStatus == 3"
            >
              挂断
            </div>
          </div>
        </div>
        <el-form
          size="mini"
          class="mT10"
          :model="callUserInfo"
          ref="callUserInfo"
          label-width="80px"
        >
          <el-form-item label="选择年级" prop="sectionId">
            <el-select
              class="info-select"
              v-model="callUserInfo.sectionId"
              placeholder="请选择"
              @change="handleSectionChange()"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="item in gradeList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <div class="section-tip">* 记得选择学生的年级奥！</div>
          </el-form-item>
          <el-form-item label="原始年级" prop="originSectionName">
            <span>{{callUserInfo.originSectionName ? callUserInfo.originSectionName : '无'}}</span>
          </el-form-item>
          <!-- <el-form-item label="家庭住址" prop="homeAddress">
            <el-input
              v-model="callUserInfo.homeAddress"
              placeholder="请输入家庭住址"
              @change="handleHomeAddressChange()"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="所在学校" prop="schoolName">
            <el-input
              v-model="callUserInfo.schoolName"
              placeholder="请输入学校名称"
              @change="handleschoolNameChange()"
            ></el-input>
          </el-form-item>
          <el-form-item label="微信状态" prop="isAddWechat">
            <el-radio-group
              v-model="callUserInfo.isAddWechat"
              @change="handleIsAddWechatChange()"
            >
              <el-radio :label="1">已添加</el-radio>
              <el-radio :label="2">未添加</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="电话所属" prop="audienceType">
            <el-radio-group
              v-model="callUserInfo.audienceType"
              @change="handleAudienceTypeChange()"
            >
              <el-radio :label="1">父亲</el-radio>
              <el-radio :label="2">母亲</el-radio>
              <el-radio :label="3">其他</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="" prop="">
            <div class="userInfo-save">
              <el-button type="primary" @click="handleSaveUesrInfo">保存</el-button>
            </div>
          </el-form-item>
          <!-- <el-form-item
            v-show="callUserInfo.importContent"
            label="初始记录"
            prop="importContent"
          >
            <div class="row-importContent">
              {{ callUserInfo.importContent ? callUserInfo.importContent : "暂无" }}
            </div> 
          </el-form-item> -->
          <el-form-item
            v-show="callUserInfo.importContent"
            label="初始记录"
            prop="importContent"
          >
            <myMoreText
              :text="callUserInfo.importContent"
              v-model="callUserInfo.isOpen"
            />
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <div class="row-remark">
              {{ callUserInfo.remark ? callUserInfo.remark : "暂无" }}
            </div>
          </el-form-item>
          <el-form-item label="跟进记录" prop="followupContent">
            <el-input
              type="textarea"
              resize="none"
              rows="3"
              show-word-limit
              maxlength="1000"
              v-model="callUserInfo.followupContent"
              placeholder="请填写跟进记录"
            ></el-input>
            <div class="add-follow-opt">
              <span class="add-follow-tip">{{
                callUserInfo.todayFollowId ? "本次已提交，如果修改再次点击提交即可!" : ""
              }}</span>
              <el-button class="mL10" type="primary" @click="handleSaveFollowContent()"
                >提交</el-button
              >
            </div>
          </el-form-item>
          <el-form-item label="" v-show="isShowRecordOpt">
            <div class="call-record-opt">
              <el-button @click="handleSendToSaleStu(callInfo.studentId)" type="primary"
                >发送给我</el-button
              >
              <el-button type="primary" @click="handleReleaseStu(callInfo.studentId)"
                >释放</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import {
  apiUpdateAutoCallInfo,
  apiGetStudentDetail,
  apiDelStuToStuPoolStu,
  apiParttimeSendToSaleStu,
  apiAddFollow,
  apiEditFollow,
  apiGetStudentDetailByAccount,
} from "@/api/sales";
import { dealPhoneNum } from "@/utils/tool";
import { apiGetValueByCode } from "@/api/manage/macro";
import { mapState, mapMutations } from "vuex";
const dayjs = require("dayjs");
export default {
  data() {
    return {
      isCloseCallBox: false,
      isShowRecordOpt: true,
      callUserInfo: {
        studentId: "",
        name: "",
        account: "",
        homeAddress: "",
        schoolName: "",
        sectionId: null,
        sectionName: null,
        isAddWechat: 1, // 1 已加微信  2 未加微信
        audienceType: null, // 1父亲 2母亲 3 其他
        customType: null, // 1 黑名单 2 真实用户 3 不需要 4 加入有用
        followupContent: "",
        todayFollowId: null,
        saveFollowLoading: false,
        _giveToSaleFlag: false,
        _isCallSuccess: false,
        importContent: null,
        isOpen: false,
        nameRemark: null,
        originSectionId: null,
        originSectionName: null,
        parentSalesDkyDeviceAccount: "",
        parentSalesUseCallPlatform: null,
        parentSalesYyyAgentAccount: "",
        yyyAgentAccount: "",
        useCallPlatform: "",
      },
      gradeList: [],
    };
  },
  inject: ["main"],
  components: {},
  computed: {
    callInfo() {
      return this.main.callInfo;
    },
    ...mapState("dialogCont", ["dialogCallInfo"]),
  },
  watch: {
    "callInfo.callStatus"(callStatus) {
      if (callStatus == 5) {
        if (process.env.VUE_APP_SERVER_ENV == "dev") {
          this.getPoolStudentDetail();
          setTimeout(() => {
            this.main.callInfo.callStatus = 3;
          }, 2000);
          return;
        }
        if (this.gradeList.length == 0) {
          this.getGradeList();
        }
        this.main.handleCallIconClick();
        console.log("执行了");
        this.getPoolStudentDetail();
        this.handleRefreshList();
        this.isShowRecordOpt = true;
        this.isCloseCallBox = false;
      } else if (callStatus == 3) {
        this.callUserInfo._isCallSuccess = true;
      } else if (callStatus == 4) {
      }
    },
  },
  mounted() {
    this.getGradeList();
  },
  methods: {
    ...mapMutations("callInfo", [
      "myParttimeIntentionListRefreshKeyChange",
      "sysStatusChange",
    ]),
    ...mapMutations("dialogCont", ["dialogCallInfoChange"]),
    /* 测试代码 */
    handleTest() {
      if (process.env.VUE_APP_SERVER_ENV == "dev") {
        this.main.handleTest();
      }
    },
    /* 保存用户信息 */
    handleSaveUesrInfo() {
      this.msgSuccess("保存信息成功！");
    },
    /* 收起来电弹框 */
    handleHiddenCallBox() {
      this.dialogCallInfoChange({
        show: false,
      });
    },
    /* 更新销售专员-兼职意向列表 */
    handleRefreshList() {
      if (this.$route.path == "/sales/saleStaff/parttimeIntention") {
        this.myParttimeIntentionListRefreshKeyChange();
      }
    },
    /* 获取学生池详情 */
    async getPoolStudentDetail() {
      try {
        this.callUserInfo.studentId = this.callInfo.studentId;
        const {
          name,
          account,
          sectionId,
          sectionName,
          schoolName,
          homeAddress,
          isAddWechat,
          customType,
          audienceType,
          remark,
          todayFollowUpInfo,
          importContent,
          nameRemark,
          originSectionId,
          originSectionName,
          dkyAgentAccount,
          parentSalesDkyDeviceAccount,
          parentSalesUseCallPlatform,
          parentSalesYyyAgentAccount,
          yyyAgentAccount,
          useCallPlatform,
        } = sessionStorage.getItem("useCallPlatform") == 2 ? await apiGetStudentDetailByAccount({studentAccount: this.callInfo.incomingNum }) : await apiGetStudentDetail({ studentId: this.callInfo.studentId });
        if(useCallPlatform == null){
          useCallPlatform = 0
        }
        if(sessionStorage.getItem("useCallPlatform") == 2){
          this.callInfo.studentId = id
          this.main.handleSaveAutoCallRecord(this.callInfo.linkedid, this.callInfo.callType)
        }
        this.main.setCurUseCallPlatform(useCallPlatform)
        if(sessionStorage.getItem("useCallPlatform") == 2){
          this.main.setCurDkyAgentAccount(yyyAgentAccount);
        }else if(sessionStorage.getItem("useCallPlatform") == 1) {
          this.main.setCurDkyAgentAccount(dkyAgentAccount);
        }
        this.callUserInfo = {
          studentId: this.callInfo.studentId,
          name,
          account: dealPhoneNum(account),
          sectionId,
          sectionName,
          schoolName,
          homeAddress,
          isAddWechat,
          audienceType,
          customType,
          remark,
          followupContent: "",
          todayFollowId: null,
          saveFollowLoading: false,
          _giveToSaleFlag: false,
          _isCallSuccess: false,
          importContent,
          isOpen: false,
          nameRemark,
          originSectionId,
          originSectionName,
          parentSalesDkyDeviceAccount,
          parentSalesUseCallPlatform,
          parentSalesYyyAgentAccount,
          yyyAgentAccount,
          useCallPlatform,
        };
        if (todayFollowUpInfo) {
          const { id, followupContent } = todayFollowUpInfo;
          this.callUserInfo.followupContent = followupContent;
          this.callUserInfo.todayFollowId = id;
        }
      } catch (err) {
        let msg = err.msg ? err.msg : "获取学生详情失败！";
        this.msgError(msg);
        console.error("getPoolStudentDetail err: ", err);
      }
    },
    /* 家庭住址改变 */
    handleHomeAddressChange() {
      this.handleUpdateStudentInfo({
        studentId: this.callUserInfo.studentId,
        homeAddress: this.callUserInfo.homeAddress,
      });
    },
    /* 所在学校改变 */
    handleschoolNameChange() {
      this.handleUpdateStudentInfo({
        studentId: this.callUserInfo.studentId,
        schoolName: this.callUserInfo.schoolName,
      });
    },
    /* 年级改变 */
    handleSectionChange() {
      let sectionName = null;
      let sectionId = this.callUserInfo.sectionId;
      let studentId = this.callUserInfo.studentId;
      if (this.callUserInfo.sectionId) {
        for (let i = 0, len = this.gradeList.length; i < len; i++) {
          if (this.gradeList[i].value == sectionId) {
            sectionName = this.gradeList[i].name;
            break;
          }
        }
      }
      this.callUserInfo.sectionName = sectionName;
      this.handleUpdateStudentInfo({
        studentId,
        sectionId,
        sectionName,
      });
    },
    /* 是否添加微信状态改变 */
    handleIsAddWechatChange() {
      this.handleUpdateStudentInfo({
        studentId: this.callUserInfo.studentId,
        isAddWechat: this.callUserInfo.isAddWechat,
      });
    },
    /* 接听人改变 */
    handleAudienceTypeChange() {
      this.handleUpdateStudentInfo({
        studentId: this.callUserInfo.studentId,
        audienceType: this.callUserInfo.audienceType,
        studentName: this.callUserInfo.name,
      });
    },
    /* 提交跟进记录 */
    async handleSaveFollowContent() {
      if (this.callInfo.callStatus == 5) {
        this.main.handleMsgInfoCallBox("请先接听电话，再提交跟进记录！");
        return;
      }
      let followupContent = this.callUserInfo.followupContent;
      if (!followupContent || !followupContent.trim()) {
        return this.msgWarn("跟进记录内容不能为空！");
      }
      try {
        this.callUserInfo.saveFollowLoading = true;
        const studentId = this.callUserInfo.studentId;
        let id = null;
        if (this.callUserInfo.todayFollowId) {
          id = await apiEditFollow({
            id: this.callUserInfo.todayFollowId,
            studentId,
            followupContent,
            userType: 1, // 1销售 2兼职 3教务
          });
        } else {
          let linkedid = null;
          if (studentId == this.callInfo.studentId) {
            linkedid = this.callInfo.linkedid;
          }
          id = await apiAddFollow({
            studentId,
            followupContent,
            userType: 1, // 1销售 2兼职 3教务
            linkedid,
          });
          if (studentId == this.callUserInfo.studentId) {
            this.callUserInfo.todayFollowId = id;
          }
        }
        this.msgSuccess("跟进记录保存成功！");
        this.callUserInfo.saveFollowLoading = false;
      } catch (err) {
        this.callUserInfo.saveFollowLoading = false;
        let msg = err.msg ? err.msg : "保存跟进记录发生异常！";
        this.msgError(msg);
        console.error("apiAddFollow err: ", err);
      }
    },
    /* 更新学生信息 */
    async handleUpdateStudentInfo(data) {
      try {
        const resFlag = await apiUpdateAutoCallInfo({
          ...data,
          sectionName: this.callUserInfo.sectionName,
          sectionId: this.callUserInfo.sectionId,
          isPersonal: 0,
          studentNameRemark: this.callUserInfo.nameRemark,
        });
        if (!resFlag) {
          this.msgError("修改信息失败！");
          console.error("handleUpdateStudentInfo err: ", "修改信息失败！");
        }
      } catch (err) {
        let msg = err.msg ? err.msg : "修改信息失败！";
        this.msgError(msg);
        console.error("handleUpdateStudentInfo err: ", err);
      }
    },
    /* 处理接听 */
    handleDKYAnswer() {
      this.main.handleDKYAnswer();
    },
    /* 处理挂断 */
    handleDKYHangup() {
      this.main.handleDKYHangup();
    },
    /* 发送给我 studentId */
    handleSendToSaleStu(id) {
      this.$confirm("确定要发送给我吗, 是否继续?", "提示", {
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            if (this._sendToSeleFlag) {
              return;
            }
            this._sendToSeleFlag = true;
            const resFlag = await apiParttimeSendToSaleStu({
              id,
            });
            this._sendToSeleFlag = false;
            if (resFlag) {
              if (id == this.callUserInfo.studentId) {
                this.isShowRecordOpt = false;
              }
              this.handleRefreshList();
              this.msgSuccess("发送成功！");
            } else {
              this.msgError("发送给我发生异常！");
            }
          } catch (err) {
            this._sendToSeleFlag = false;
            let msg = err.msg ? err.msg : "发送失败！";
            this.msgError(msg);
            console.error("handleSendToSaleStu err: ", err);
          }
        })
        .catch(() => {});
    },
    /* 释放 studentId */
    handleReleaseStu(id) {
      this.$confirm("确定要释放吗, 是否继续?", "提示", {
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            if (this._releaseFlag) {
              return;
            }
            this._releaseFlag = true;
            const resFlag = await apiDelStuToStuPoolStu({
              id,
            });
            this._releaseFlag = false;
            if (resFlag) {
              if (id == this.callUserInfo.studentId) {
                this.isShowRecordOpt = false;
              }
              this.handleRefreshList();
              this.msgSuccess("释放成功！");
            } else {
              this.msgError("释放发生异常！");
            }
          } catch (err) {
            this._releaseFlag = false;
            let msg = err.msg ? err.msg : "释放失败！";
            this.msgError(msg);
            console.error("handleReleaseStu err: ", err);
          }
        })
        .catch(() => {});
    },
    /* 获取年级列表 */
    async getGradeList() {
      try {
        const resData = await apiGetValueByCode({
          parentCodeList: ["sys.tescher.disciplineProfessorGrade"],
        });
        this.gradeList = resData["sys.tescher.disciplineProfessorGrade"];
        this.gradeList.unshift({
          name: "未知",
          value: null,
        });
      } catch (err) {}
    },
    /* 关闭通话弹框 */
    handleCloseCallBox() {
      this.isCloseCallBox = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.call-box-area {
  position: fixed;
  left: 100px;
  top: 100px;
  z-index: 9999;
  width: 400px;
  min-height: 600px;
  border: 2px solid #c0c4cc;
  box-sizing: border-box;
  border-radius: 6px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #ffffff;
  .call-header {
    border-radius: 6px 6px 0 0;
    height: 30px;
    background-color: #f2f6fc;
    width: 100%;
    text-align: center;
    line-height: 30px;
    color: #909399;
    cursor: move;
    user-select: none;
    position: relative;
  }
  .no-call {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .icon-close {
    position: absolute;
    right: 10px;
    top: 10px;
    &-box {
      top: 0px;
    }
    cursor: pointer;
    &:hover {
      color: #f56c6c;
    }
  }
  .call-tip-2 {
    color: #c0c4cc;
    margin-top: 10px;
  }
  .call-box {
    flex: 1;
    position: relative;
    z-index: 100;
    width: 396px;
    background-color: #ffffff;
    max-height: calc(100vh - 40px);
    border: 1px solid #f2f6fc;
    font-size: 60px;
    padding: 20px 10px 0px 10px;
    overflow-y: auto;
    .call-box-inner {
      height: 100%;
      overflow-y: auto;
      @include scrollbar();
      padding-right: 6px;
    }
    .section-tip {
      color: #f56c6c;
      font-size: 12px;
      font-weight: bold;
    }
    .call-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .call-username {
        font-weight: 600;
        line-height: 36px;
        font-size: 16px;
        padding: 0 20px;
        width: 100%;
        @include ellipsis;
        text-align: center;
      }
      .call-phone {
        font-weight: 600;
        line-height: 30px;
      }
      .call-tip {
        line-height: 36px;
        color: #c0c4cc;
      }
      .call-btn-area {
        display: flex;
      }
      .call-btn {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 12px;
        cursor: pointer;
      }
      .call-before {
        background-color: #67c23a;
        color: #ffffff;
        margin-right: 10px;
      }
      .call-ing {
        background-color: #f56c6c;
        color: #ffffff;
      }
    }
    .userInfo-save {
      text-align: right;
    }
    .info-select {
      width: 100%;
    }
    .give-to-sale {
      text-align: right;
      .icon-give-to {
        font-size: 12px;
      }
    }
    .add-follow-opt {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .add-follow-tip {
        flex: 1;
        line-height: 20px;
        color: #f56c6c;
      }
    }
    .call-tip {
      color: #f56c6c;
    }
    .call-select {
      width: 100% !important;
    }
    .call-record-opt {
      text-align: right;
    }
  }
}
</style>
