<template>
  <div class="header" :style="{'min-width': isMobile ? '960px' : '1160px'}">
    <div class="header-left">
      <div
        class="nav-ctrl"
        :title="navIsOpen ? '收起导航' : '展开导航'"
        @click="handleNavCtrl"
      >
        <i class="icon-ctrl el-icon-s-fold" v-if="navIsOpen"></i>
        <i class="icon-ctrl el-icon-s-unfold" v-else></i>
      </div>
    </div>
    <div class="header-middle">
      <div v-show="callInfo.isAllocDkyAccount && saleType">
        <div v-show="dkyAgentType != 2 && sysCallInfo.flagOn" class="call-flagOn">
          <span class="call-status-label" @click="testClick">电话状态：</span>
          <span class="phone-status" v-show="callInfo._lastHandBusyType == 1"
            >未开启状态</span
          >
          <span class="phone-status" v-show="callInfo._lastHandBusyType == 0"
            >运行中状态</span
          >
          <el-button
            class="mL10"
            v-show="callInfo._lastHandBusyType == 1"
            @click="handleStartOrPauseCall(0)"
            :title="
              saleType == 1
                ? '开启后可以收到兼职的转接电话！'
                : '开启后可以收到预测外呼电话！'
            "
            type="success"
            >开启</el-button
          >
          <el-button
            v-show="callInfo._lastHandBusyType == 0"
            :title="
              saleType == 1
                ? '开启后可以收到兼职的转接电话！'
                : '开启后可以收到预测外呼电话！'
            "
            @click="handleStartOrPauseCall(1)"
            type="danger"
            >暂停</el-button
          >
          <!-- <span class="call-tip" v-show="saleType == 2">开启后可以收到预测外呼电话！</span>
          <span class="call-tip" v-show="saleType == 1">开启后可以收到兼职的转接电话！</span> -->
          <!-- <span class="queue-info" v-show="saleType == 2"> 当前队列：{{ queueName }} </span> -->
          <span class="sys-calltime" v-show="sysStatus.startTime && sysStatus.endTime"
            >电话时间：{{ sysStatus.startTime }}-{{ sysStatus.endTime }}</span
          >
        </div>
        <div v-show="dkyAgentType != 2 && !sysCallInfo.flagOn" class="call-flagOff">
          <span class="call-status-label">电话状态：</span
          ><span class="call-off-cause">{{ sysCallInfo.causeTxt }}</span>
        </div>
        <div v-show="dkyAgentType == 2" class="call-flagOff">
          <span class="call-status-label">电话状态：</span
          ><span class="call-off-cause">已被禁用</span>
        </div>
      </div>
    </div>
    <div class="header-right">
      <span class="work-time" v-show="saleType == 2">
        <span>工作时间：</span>
        <span v-if="dialogWorkTimeData.startTime && dialogWorkTimeData.endTime"
          >{{ dialogWorkTimeData.startTime }}-{{ dialogWorkTimeData.endTime }}</span
        >
        <span v-else>暂未设置</span>
      </span>
      <el-link
        class="mL20"
        type="primary"
        v-show="saleType == 2"
        @click="handleSetWorkTime"
        >设置工作时间</el-link
      >
      <div class="user-info" @click="handleSeeUserInfo">
        <img
          class="head-img"
          :src="
            userInfo.photo ? userInfo.photo : require('@/assets/image/default_head.png')
          "
          alt=""
        />
        <span class="user-name" :title="userInfo.name">{{ userInfo.name }}</span>
      </div>
      <div class="opt-item" @click="handleEditPassword">
        <i class="iconfont icon-sys-ziyuanxhdpi"></i>
        <span class="item-txt">修改密码</span>
      </div>
      <div class="opt-item" @click="handleLogout">
        <i class="iconfont icon-tuichu"></i>
        <span class="item-txt">退出登录</span>
      </div>
    </div>
    <!-- 设置工作时间 -->
    <el-dialog
      :close-on-click-modal="false"
      title="设置工作时间"
      :visible.sync="dialogWorkTimeData.show"
      width="500px"
      class="dialog-meeting-set"
    >
      <div class="dialog-content" style="padding-right: 10px">
        <el-form :model="dialogWorkTimeData" ref="dialogWorkTime" label-width="80px">
          <el-form-item label="工作时间" prop="selectTime">
            <el-time-picker
              is-range
              v-model="dialogWorkTimeData.selectTime"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              value-format="HH:mm:ss"
            >
            </el-time-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogWorkTimeData.show = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleConfirmSetWorkTime"
          >保 存</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改密码 -->
    <el-dialog
      :close-on-click-modal="false"
      title="修改密码"
      :visible.sync="dialogEditPasswordData.show"
      width="500px"
      class="dialog-meeting-set"
    >
      <div class="dialog-content" style="padding-right: 10px">
        <el-form
          :model="dialogEditPasswordData"
          :rules="dialogEditPasswordDataRules"
          ref="editPassword"
          label-width="100px"
        >
          <el-form-item label="原密码" prop="oldPassword">
            <el-input
              v-model="dialogEditPasswordData.oldPassword"
              placeholder="请输入原密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword">
            <el-input
              v-model="dialogEditPasswordData.newPassword"
              placeholder="请输入新密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="reNewPassword">
            <el-input
              v-model="dialogEditPasswordData.reNewPassword"
              placeholder="请输入确认密码"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogEditPasswordData.show = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="handleConfirmEditPassword"
          >保 存</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      title="编辑用户信息"
      :visible.sync="dialogUserDataShow"
      width="500px"
    >
      <div>
        <el-form
          ref="dialogUserData"
          size="small"
          :model="dialogUserData"
          :rules="dialogUserDataRules"
          label-width="100px"
        >
          <el-form-item label="用户头像" prop="photo">
            <div title="点击修改" class="img-select" @click="handleImgSelectClick">
              <img
                v-if="dialogUserData.photo"
                class="add-head"
                :src="dialogUserData.photo"
                alt=""
              />
              <span v-else class="el-icon-plus add-icon"></span>
            </div>
            <input
              type="file"
              @change="handleImgSelectChange"
              accept="image/*"
              ref="imgSelect"
              style="display: none"
            />
          </el-form-item>
          <el-form-item label="用户名" prop="username">
            <el-input
              disabled
              v-model="dialogUserData.username"
              placeholder="请输入用户名"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="真实姓名" prop="name">
            <el-input
              v-model="dialogUserData.name"
              placeholder="请输入真实姓名"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input
              v-model="dialogUserData.mobile"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogUserDataShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleConfirmUpdateUserInfo"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { uploadSmallFile } from "@/utils/ossUploadFile";
import { apiGetUserInfo, apiUpdateUser } from "@/api/manage/user";
import { apiSetUserWorkHours, apiGetUserWorkHours } from "@/api/index";
import { apiUpdatePassword } from "@/api/index";
import { apiLogout } from "@/api/index";
import { mapMutations, mapState } from "vuex";
import { isMobile } from '@/utils/tool'
const queueNameObj = {
  153: "内部队列1",
  10037001002154: "内部队列2",
  10037001002155: "书法队列1",
  10037001002156: "书法队列2",
  936: "lcx测试",
  70000001128937: "内部测试1",
  70000001128938: "lcx测试",
  70000001128939: "书法测试2",
};
export default {
  data() {
    return {
      isMobile: isMobile(),
      queueName: "",
      /* 修改密码数据 */
      dialogEditPasswordData: {
        show: false,
        newPassword: "",
        oldPassword: "",
        reNewPassword: "",
      },
      dialogEditPasswordDataRules: {
        oldPassword: [
          { required: true, message: "原密码不能为空", trigger: "change" },
          {
            validator: (rule, value, callback) => {
              if (value && !value.trim()) {
                return callback(new Error("原密码不能为空"));
              }
              return callback();
            },
          },
        ],
        newPassword: [
          { required: true, message: "新密码不能为空", trigger: "change" },
          {
            validator: (rule, value, callback) => {
              if (value && !value.trim()) {
                return callback(new Error("新密码不能为空"));
              }
              return callback();
            },
          },
        ],
        reNewPassword: [
          { required: true, message: "确认密码不能为空", trigger: "change" },
          {
            validator: (rule, value, callback) => {
              if (value && !value.trim()) {
                return callback(new Error("确认密码不能为空"));
              }
              return callback();
            },
          },
        ],
      },
      dialogUserDataShow: false,
      dialogUserData: {
        photo: "",
        username: "",
        mobile: "",
        name: "",
      },
      dialogUserDataRules: {
        photo: [{ required: true, message: "头像不能为空", trigger: "change" }],
        username: [{ required: true, message: "用户名不能为空", trigger: "change" }],
        name: [{ required: true, message: "真实姓名不能为空", trigger: "change" }],
        mobile: [
          {
            validator: (rule, value, callback) => {
              if (value && !/^1\d{10}$/.test(value)) {
                return callback(new Error("手机号码格式不正确！"));
              }
              return callback();
            },
          },
        ],
      },
      dialogWorkTimeData: {
        show: false,
        userId: null,
        startTime: null,
        endTime: null,
        selectTime: null,
      },
    };
  },
  components: {},
  inject: ["main"],
  computed: {
    ...mapState(["authorities", "userInfo", "navIsOpen"]),
    ...mapState("callInfo", ["sysStatus"]),
    sysCallInfo() {
      return this.main.sysCallInfo;
    },
    callInfo() {
      return this.main.callInfo;
    },
    saleType() {
      return this.main.saleType;
    },
    dkyAgentType() {
      return this.main.dkyAgentType;
    },
  },
  mounted() {
    const queueNumber = sessionStorage.getItem("queueNumber");
    this.queueName = queueNumber ? queueNameObj[queueNumber] : "无队列号";
    this.getUserInfo();
    if (this.saleType == 2) {
      this.getWorkTimeDetail();
    }
  },
  methods: {
    ...mapMutations(["updateUserInfo", "updateNavIsOpen"]),
    ...mapMutations("callInfo", ["sysStatusChange"]),
    /* test */
    testClick() {
      console.log("this.callInfo._lastHandBusyType: ", this.callInfo._lastHandBusyType);
    },
    /* 开启或者暂停 */
    handleStartOrPauseCall(type) {
      this.main.handleBusyOrFreeForHand(type);
    },
    /* 点击选择头像 */
    handleImgSelectClick() {
      this.$refs["imgSelect"].value = "";
      this.$refs["imgSelect"].click();
    },
    /* 处理选择了头像 */
    async handleImgSelectChange() {
      const file = this.$refs["imgSelect"].files[0];
      try {
        const { url } = await uploadSmallFile(file);
        this.dialogUserData.photo = url;
      } catch (err) {
        let msg = err.msg ? err.msg : "图片上传发生异常！";
        console.error("uploadSmallFile err: ", err);
        this.msgError(msg);
      }
    },
    /* 点击查看用户信息 */
    handleSeeUserInfo() {
      const { photo, username, mobile, name } = this.userInfo;
      this.dialogUserDataShow = true;
      this.dialogUserData = {
        photo,
        username,
        mobile,
        name,
      };
    },
    /* 保存用户信息 */
    handleConfirmUpdateUserInfo() {
      this.$refs["dialogUserData"].validate(async (valid) => {
        if (valid) {
          if (this._saveUserInfoFlag) {
            return;
          }
          this._saveUserInfoFlag = true;
          try {
            const { photo, username, mobile, name } = this.dialogUserData;
            const { roleIdList, id } = this.userInfo;
            const data = await apiUpdateUser({
              photo,
              username,
              mobile,
              name,
              id,
              roleIdList,
              orgId: this.userInfo.orgId,
            });
            this._saveUserInfoFlag = false;
            if (!data) {
              return this.msgError("用户信息修改失败！");
            }
            this.updateUserInfo({
              photo,
              username,
              name,
              mobile,
            });
            this.dialogUserDataShow = false;
            this.msgSuccess("用户信息修改成功！");
          } catch (err) {
            this._saveUserInfoFlag = false;
            let msg = err.msg ? err.msg : "保存用户信息发生异常！";
            console.error("handleConfirmUpdateUserInfo err: ", err);
            this.msgError(msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /* 获取用户信息 */
    async getUserInfo() {
      try {
        const {
          photo,
          name,
          username,
          id,
          mobile,
          roleIdList,
          orgId,
          orgName,
          tsystemCallTime,
        } = await apiGetUserInfo({ userId: sessionStorage.getItem("userId") });
        sessionStorage.setItem("orgId", orgId);
        this.updateUserInfo({
          photo,
          name,
          username,
          mobile,
          id,
          roleIdList,
          orgId,
          orgName,
        });
        if (tsystemCallTime) {
          const { startTime, endTime, status } = tsystemCallTime;
          this.sysStatusChange({
            startTime,
            endTime,
            status,
            dataFlag: true,
          });
        } else {
          this.sysStatusChange({
            startTime: null,
            endTime: null,
            status: null,
            dataFlag: true,
          });
        }
      } catch (err) {
        let msg = err.msg ? err.msg : "获取用户信息发生异常！";
        this.msgError(msg);
        console.error("getUserInfo err: ", err);
      }
    },
    /* 消息通知选择发生改变 */
    handleMsgNoticeSelectionChange(val) {
      console.log("handleMsgNoticeSelectionChange: ", val);
      this.msgMultipleSelection = val;
    },
    /* 处理修改密码 */
    handleEditPassword() {
      this.dialogEditPasswordData.show = true;
      this.$nextTick(() => {
        if (this.$refs["editPassword"]) {
          this.$refs["editPassword"].resetFields();
        }
      });
    },
    /* 确定修改密码 */
    handleConfirmEditPassword() {
      this.$refs["editPassword"].validate(async (valid) => {
        if (valid) {
          const { oldPassword, newPassword, reNewPassword } = this.dialogEditPasswordData;
          if (newPassword != reNewPassword) {
            return this.msgWarning("新密码和确认密码不一致！");
          }
          try {
            if (this._savePwdFlag) {
              return;
            }
            this._savePwdFlag = true;
            const data = await apiUpdatePassword({
              pswd: oldPassword,
              newPswd: newPassword,
            });
            this._savePwdFlag = false;
            if (!data) {
              return this.msgError("修改密码失败！");
            }
            this.msgSuccess("密码修改成功！");
            this.dialogEditPasswordData.show = false;
          } catch (err) {
            this._savePwdFlag = false;
            let msg = err.msg ? err.msg : "修改密码发生异常！";
            console.error("apiUpdatePassword err: ", err);
            this.msgError(msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /* 处理退出登录 */
    handleLogout() {
      this.$confirm("确定要退出登录吗?", "提示", {
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            await apiLogout();
            sessionStorage.clear("Authorization");
            this.msgSuccess("退出成功！");
            this.$router.push({
              path: "/login",
            });
            if (this.main.saleType == 1 || this.main.saleType == 2) {
              setTimeout(() => {
                location.reload();
              }, 1000);
            }
            setTimeout(() => {}, 2000);
          } catch (err) {
            sessionStorage.clear("Authorization");
            this.msgSuccess("退出成功！");
            console.error("apiLogout err: ", err);
            this.$router.push({
              path: "/login",
            });
            if (this.main.saleType == 1 || this.main.saleType == 2) {
              setTimeout(() => {
                location.reload();
              }, 1000);
            }
          }
        })
        .catch((err) => {});
    },
    /* 导航的折叠展开 */
    handleNavCtrl() {
      const navIsOpen = !this.navIsOpen;
      this.updateNavIsOpen(navIsOpen);
    },
    /* 设置工作时间 */
    handleSetWorkTime() {
      this.dialogWorkTimeData.show = true;
    },
    /* 获取工作时间详情 */
    async getWorkTimeDetail() {
      try {
        const resData = await apiGetUserWorkHours();
        if (resData) {
          const { endTime, startTime } = resData;
          this.dialogWorkTimeData.startTime = startTime;
          this.dialogWorkTimeData.endTime = endTime;
          this.dialogWorkTimeData.selectTime = [startTime, endTime];
        } else {
          this.dialogWorkTimeData.startTime = null;
          this.dialogWorkTimeData.endTime = null;
          this.dialogWorkTimeData.selectTime = null;
        }
      } catch (err) {
        let msg = err.msg ? err.msg : "获取工作时间详情发生异常！";
        this.msgError(msg);
        console.error("apiGetUserWorkHours err: ", err);
      }
    },
    /* 确定保存工作时间 */
    async handleConfirmSetWorkTime() {
      console.log(
        "this.dialogWorkTimeData.selectTime: ",
        this.dialogWorkTimeData.selectTime
      );
      try {
        let startTime = "",
          endTime = "";
        if (this.dialogWorkTimeData.selectTime) {
          startTime = this.dialogWorkTimeData.selectTime[0];
          endTime = this.dialogWorkTimeData.selectTime[1];
        }
        const resFlag = await apiSetUserWorkHours({
          startTime,
          endTime,
        });
        if (resFlag) {
          this.dialogWorkTimeData.startTime = startTime;
          this.dialogWorkTimeData.endTime = endTime;
          this.dialogWorkTimeData.show = false;
        } else {
          this.msgError("保存工作时间发生异常！");
        }
      } catch (err) {
        let msg = err.msg ? err.msg : "保存工作时间发生异常！";
        this.msgError(msg);
        console.error("apiSetUserWorkHours err: ", err);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.img-select {
  height: 84px;
  width: 84px;
  border: 2px solid #ebebeb;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .add-head {
    width: 84px;
    height: 84px;
  }
  .add-icon {
    font-size: 30px;
  }
}
.header {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c0c4cc;
  box-sizing: border-box;
  padding: 0 10px 0 20px;
  min-width: 1160px;
  .header-left {
    display: flex;
    align-items: center;
    .nav-ctrl {
      cursor: pointer;
      .icon-ctrl {
        font-size: 22px;
        color: #222222;
        &:hover {
          color: #409eff;
        }
      }
    }
  }
  .header-middle {
    flex: 1;
    margin-left: 20px;
    .call-off-cause {
      color: #f56c6c;
      font-weight: bold;
    }
    .phone-status {
      color: #909399;
    }
    .call-tip {
      margin-left: 10px;
      color: #909399;
    }
    .queue-info {
      margin-left: 10px;
      color: #909399;
    }
    .sys-calltime {
      margin-left: 10px;
      color: #67c23a;
      font-weight: bold;
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    .work-time {
      color: #67c23a;
      font-weight: bold;
    }
    .meeting-set {
      .value {
        margin: 0 20px 0 10px;
      }
    }
    .user-info {
      flex: 1;
      margin-left: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      overflow: hidden;
      display: flex;
      justify-content: flex-end;
      .head-img {
        width: 20px;
        height: 20px;
        border-radius: 20px;
      }
      .user-name {
        max-width: 138px;
        margin-left: 4px;
        margin-right: 4px;
        color: #222222;
        @include ellipsis;
      }
    }
    .opt-item {
      padding: 0 10px;
      color: #222222;
      cursor: pointer;
      .iconfont {
        color: #222222;
      }
      .item-txt {
        margin-left: 4px;
      }
      &:hover {
        color: rgb(64, 158, 255);
        .iconfont {
          color: rgb(64, 158, 255);
        }
      }
    }
  }
  .dialog-meeting-set {
    .dialog-content {
      .label {
        margin-right: 20px;
      }
    }
  }
  .dialog-btn-area-msg {
    height: 40px;
    text-align: right;
  }
  .dialog-content-msg {
    height: 50vh;
  }
  .dialog-bottom {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
